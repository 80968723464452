import React from "react";
import H1 from "../../components/h1";
import SEO from "../../components/seo";
import Par from "../../components/Par";

// Download link - http://www.fbrennangastro.com.au/downloads/gesaercp(2).pdf

const title = "Endoscopic Retrograde Cholangiopancreatography (ERCP)";
const Services = () => {
	return (
		<div>
			<SEO
				title={title}
				keywords={[
					title,
					`Gastroenterology services`,
					`Endoscopy services`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank`,
					`Dr Brennan`,
					`Dr Spiro`,
					`Dr Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Duy Pham`,
					`Dr Claire Gordon`,
				]}
				description={title}
			/>
			<H1 text={title} />
			<Par>
				Endoscopic retrograde cholangiopancreatography (ERCP) is a technique that combines the use of endoscopy and
				fluoroscopy to diagnose and treat certain problems of the digestive system such as gallstones, inflammatory
				strictures (scars) or cancer.
			</Par>
			<Par>
				The patient is sedated or anaesthetised. During the procedure a thin telescope (endoscope) is inserted down the
				throat to allow the doctor to see inside the digestive system.
			</Par>
			<Par>
				The doctor then injects a dye into the bile duct or pancreatic duct so it can be seen on an x-ray (fluroscopy)
				while the necessary procedure is performed.
			</Par>
		</div>
	);
};

export default Services;
